* {
  min-height: 0; /* Rendering problems using flexbox in Firefox and Chrome 48 */
  min-width: 0; /* Rendering problems using flexbox in Firefox and Chrome 48 */
}

.img-full {
  width: 100%;
  height: auto;
}

.img-bg {
  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.text-faded {
  color: #aaaaaa;
}

.ellipses {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.bg-facebook {
  background-color: $bg-facebook;
}

.bg-twitter {
  background-color: $bg-twitter;
}

.bg-google {
  background-color: $bg-google;
}

.h3 {
  font-size: 30px;
  line-height: 45px;
  color: #fff;
  text-transform: uppercase;
  font-family: 'TrajanPro3', serif;
}
.h1-detail {
  font-size: 30px;
  line-height: 45px;
  text-transform: uppercase;
  font-family: 'TrajanPro3', serif;
  color: #373737;
}

.h4 {
  font-size: 16px;
  line-height: 28px;
  color: #232f5d;
  text-transform: uppercase;
}

.h1 {
  font-size: 36px;
  line-height: 45px;
  text-transform: uppercase;
  font-family: 'TrajanPro3', serif;
  color: #373737;
}

p {
}

@media (max-width: 991.98px) {
  .h1 {
    font-size: 20px;
    line-height: 22px;
  }
  .h3 {
    font-size: 18px;
    line-height: 22px;
    color: #fff;
    text-transform: uppercase;
  }
}

@media (max-width: 575.98px) {
  .h1 {
    font-size: 18px;
    line-height: 22px;
    margin-top: 30px !important;
  }
  .h1-detail {
    font-size: 18px;
    line-height: 22px;
    margin-top: 30px !important;
  }
  .h3 {
    font-size: 16px;
    line-height: 22px;
    color: #fff;
    text-transform: uppercase;
  }
}

/* ==========================================================================
Footer
========================================================================== */
.footer {
  border-top: 0;
  padding: 20px 0 20px;
  .nav-footer {
    ul {
      margin: 0;
      li {
        margin-right: 14px;
      }
      a {
        font-size: 14px;
        text-transform: uppercase;
        color: $mine-shaft;
        &:hover {
          opacity: 0.8;
          text-decoration: none;
        }
      }
    }
  }
  .copyright {
    margin: 0;
  }
}
@media (min-width: 576px) {
  .footer {
    padding: 20px 16px 20px;
  }
}

@media (max-width: 575.98px) {
  .footer {
    .copyright {
      margin: 10px 0 0 0;
    }
  }
}

/* Generated by Fontie <http://fontie.pixelsvsbytes.com> */

@font-face {
  font-family: 'AvenirBook';
  src: url('../fonts/AvenirBook.eot');
  src: local('../fonts/AvenirBook'),
    url('../fonts/AvenirBook.eot?#iefix') format('embedded-opentype'),
    url('../fonts/AvenirBook.woff2') format('woff2'),
    url('../fonts/AvenirBook.woff') format('woff'),
    url('../fonts/AvenirBook.ttf') format('truetype'),
    url('../fonts/AvenirBook.otf') format('opentype'),
    url('../fonts/AvenirBook.svg#Avenir Book') format('svg');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U + 0020-FB02;
}

/* Generated by Fontie <http://fontie.pixelsvsbytes.com> */

@font-face {
  font-family: 'TrajanPro3';
  src: url('TrajanPro3.eot');
  src: local('TrajanPro3-Regular'), local(TrajanPro3),
    url('../fonts/TrajanPro3.eot?#iefix') format('embedded-opentype'),
    url('../fonts/TrajanPro3.woff2') format('woff2'),
    url('../fonts/TrajanPro3.woff') format('woff'),
    url('../fonts/TrajanPro3.ttf') format('truetype'),
    url('../fonts/TrajanPro3.otf') format('opentype'),
    url('../fonts/TrajanPro3.svg#Trajan Pro 3') format('svg');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U + 0020-FB02;
}

/* ==========================================================================
Header
========================================================================== */
.header {
  position: relative;
  padding: 15px 16px 15px;
  height: 95px;
  @include flexbox;
  @include flex-flow(row nowrap);
  @include align-items(center);
  .top {
    //margin: 20px 0 17px;
    .slogan {
      font-size: 36px;
      line-height: 45px;
      color: #1d1d1b;
      font-family: 'AvenirBook', serif;
      letter-spacing: 1.2px;
      text-transform: uppercase;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      margin-right: auto;
    }
  }
  .logo {
    position: absolute;
    top: 50%;
    left: 50%;
    @include transform(translate(-50%, -50%));
    img {
      width: 50px;
      height: 62px;
    }
  }
  .humburger {
    position: relative;
    width: 40px;
    height: 27px;
    cursor: pointer;
    display: block;
    span {
      font-family: 'Avenir Next', 'Avenir', sans-serif;
      position: absolute;
      width: 40px;
      height: 3px;
      left: 0;
      background: $mine-shaft;
      border-radius: 3px;
      z-index: 1;
      @include transition(all 0.3s ease-in-out);
    }
    span:nth-child(1) {
      top: 0;
    }
    span:nth-child(2) {
      top: 12px;
    }
    span:nth-child(3) {
      top: 24px;
    }
  }
  .active-humburger {
    span:nth-child(1) {
      opacity: 1;
      -webkit-transform: translateY(9px) rotateZ(45deg);
      -moz-transform: translateY(9px) rotateZ(45deg);
      -ms-transform: translateY(9px) rotateZ(45deg);
      -o-transform: translateY(9px) rotateZ(45deg);
      transform: translateY(9px) rotateZ(45deg);
    }
    span:nth-child(2) {
      width: 0;
    }
    span:nth-child(3) {
      -webkit-transform: translateY(-15px) rotateZ(-45deg);
      -moz-transform: translateY(-15px) rotateZ(-45deg);
      -ms-transform: translateY(-15px) rotateZ(-45deg);
      -o-transform: translateY(-15px) rotateZ(-45deg);
      transform: translateY(-15px) rotateZ(-45deg);
    }
  }
}

@media (max-width: 991.98px) {
  .header .top .slogan {
    font-size: 18px;
  }
  .header .logo {
    margin-right: 70px;
  }
}

@media (max-width: 599px) {
  .header {
    padding: 10px 0 13px;
    height: 54px;
    .logo {
      img {
        width: 25px;
        height: 31px;
      }
    }
    .top {
      .slogan {
        margin: 0;
        line-height: 30px;
        font-size: 12px;
      }
    }
    .humburger {
      margin-top: 0;
      margin-left: auto;
      span {
        width: 25px;
      }
      span:nth-child(1) {
        top: 8px;
      }
      span:nth-child(2) {
        top: 16px;
      }
      span:nth-child(3) {
        top: 24px;
      }
    }
    .active-humburger {
      span:nth-child(1) {
        -webkit-transform: translateY(5px) rotateZ(45deg);
        -moz-transform: translateY(5px) rotateZ(45deg);
        -ms-transform: translateY(5px) rotateZ(45deg);
        -o-transform: translateY(5px) rotateZ(45deg);
        transform: translateY(5px) rotateZ(45deg);
      }
      span:nth-child(3) {
        top: 16px;
        -webkit-transform: translateY(-3px) rotateZ(-45deg);
        -moz-transform: translateY(-3px) rotateZ(-45deg);
        -ms-transform: translateY(-3px) rotateZ(-45deg);
        -o-transform: translateY(-3px) rotateZ(-45deg);
        transform: translateY(-3px) rotateZ(-45deg);
      }
    }
  }
}

.banner-chao {
  height: 535px;
}
.image-chao-content > img {
  width: 100%;
  height: auto;
}
.chao-content-detail {
  text-align: center;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  padding: 16px 16px 60px;
}
.container-chao {
  background-color: #f9f7f4;
}
.ls-hallmarks-chao::before {
  content: '';
  display: block;
  width: 180px;
  height: 2px;
  background-color: #000;
  margin: 0 auto;
  margin-bottom: 16px;
}
.ls-hallmarks-chao::after {
  content: '';
  display: block;
  width: 180px;
  height: 2px;
  background-color: #000;
  margin: 0 auto;
  margin-top: 16px;
}
.h1-container {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  text-align: center;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-bottom: 32px;
}
.h1-chao-content {
  font-size: 24px;
  letter-spacing: 8px;
  color: #232f5d;
  padding: 32px 0;
  margin-top: 5px;
}
.hallmarks-chao {
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.6px;
  color: #373737;
  margin-bottom: 24px;
}
.ls-hallmarks-chao {
  font-size: 22px;
  line-height: 43px;
  letter-spacing: 3.8px;
  color: #373737;
}
.text-chao-content {
  font-size: 14px;
  letter-spacing: 0.6px;
  color: #373737;
  line-height: 21px;
  text-align: justify;
}
.text-chao-content p {
  margin: 0 0 24px 0;
  padding: 0;
}
@media screen and (min-width: 576px) {
  .chao-content-detail {
    padding: 32px 32px 60px;
  }
}
@media screen and(min-width: 768px) {
  .h1-chao-content {
    font-size: 43px;
    line-height: 70px;
    letter-spacing: 14.9px;
    padding: 35px 0 30px 0;
  }
  .h1-container {
    max-width: 638px;
    margin: 0 auto;
    padding: 0;
  }
  .ls-hallmarks-chao {
    font-size: 28px;
    line-height: 43px;
    letter-spacing: 3.8px;
  }
  .chao-content-detail {
    height: 817px;
    padding: 41px 0 0 0;
  }
  .text-chao-content {
    padding: 0 135px;
  }
  .banner-chao {
    height: 817px;
  }
  #h1-lifestyle {
    margin-bottom: 85px;
  }
  #h1-convenient {
    margin-bottom: 120px;
  }
  #h1-comfortable {
    margin-bottom: 100px;
  }
  #h1-connected {
    margin-bottom: 80px;
  }
  .h1-detail-chao {
    letter-spacing: 3px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1279px) {
  .image-chao-content,
  .chao-content-detail {
    width: 50%;
  }
  .float-left-pc {
    float: left;
  }
  .float-right-pc {
    float: right;
  }
  .chao-content::after {
    content: '';
    display: block;
    clear: both;
  }
  .chao-content-detail {
    height: auto;
    padding: 0;
  }
  .text-chao-content {
    padding: 0 32px;
  }
  #h1-lifestyle .h1-chao-content {
    padding: 5px 0 0 0;
  }
}
@media screen and (min-width: 1280px) {
  .image-chao-content,
  .chao-content-detail {
    width: 50%;
  }
  .float-left-pc {
    float: left;
  }
  .float-right-pc {
    float: right;
  }
  .chao-content::after {
    content: '';
    display: block;
    clear: both;
  }
  .chao-content-detail {
    height: auto;
  }
  .image-chao-content {
    margin-bottom: 0;
  }
}

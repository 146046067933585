/*!--------------------------------------------------------------------------------

    Theme Name: Frontend Seed 4
    Version:    1.4.3
    Author:     trungnghia112 <trungnghia112@gmail.com>

-----------------------------------------------------------------------------------*/
//  Bootstrap
//  Fonts
//  Global
//  Third party
//  Layout
//  Content
//---------------------------------------------------------------------------------*/
//
// =Constants
// --------------------------------------------------
@import 'custom-variables';
//
// =Bootstrap
// --------------------------------------------------
@import 'custom-bootstrap';
//
// =Fontawesome
// --------------------------------------------------
@import '../fonts/fontawesome/web-fonts-with-css/scss/fa-regular';
@import '../fonts/fontawesome/web-fonts-with-css/scss/fa-brands';
@import '../fonts/fontawesome/web-fonts-with-css/scss/fa-solid';
@import '../fonts/fontawesome/web-fonts-with-css/scss/fontawesome';
//
// =Third parties
//
// @import: '../third_party/malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css';
// =Fonts
// --------------------------------------------------
@import 'partials/fonts';
//
// =Global
// --------------------------------------------------
@import 'general/mixin';
@import 'general/setting';
@import 'partials/constants';
@import 'partials/typography';
@import 'partials/forms';
@import 'partials/buttons';
@import 'partials/scrollbar-measure';
@import 'partials/modals';
@import 'partials/icons';
@import 'partials/grid';
//
// =Modules
// --------------------------------------------------
@import 'modules/IconHoverEffects';
@import 'modules/facebook-responsive';
@import 'modules/list';
@import 'modules/banner';
@import 'modules/preloader';
//
// =Layout
// --------------------------------------------------
@import 'layout/header';
@import 'layout/footer';
@import 'layout/content';
@import 'layout/menu';

//
// =Content
// --------------------------------------------------
@import 'pages/home';
@import 'pages/visionaries';
@import 'pages/services';
@import 'pages/contact';
@import 'pages/projects';
@import 'pages/partnership';
@import 'pages/news';
@import 'pages/privacy-policy';
@import 'pages/sitemap';
@import 'pages/legacy';
@import 'pages/ourbrands';
@import 'pages/celadon';
@import 'pages/chao';
@import 'pages/legacy-detail';

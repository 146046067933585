.container-celadon {
  background-color: #fff;
}
.banner-celadon {
  height: 535px;
  padding: 0 16px;
  position: relative;
}
.text-banner-celadon {
  font-size: 30px;
  line-height: 45px;
  color: #fff;
  width: 100%;
  letter-spacing: 3px;
  text-align: center;
  position: absolute;
  bottom: 66px;
  left: 0;
  right: 0;
}
.content-detail {
  text-align: center;
  background-color: #fff;
  padding: 40px 16px;
}
.text-logo-celadon {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.6px;
  color: #373737;
}
.text-pc .text-logo-celadon {
  line-height: 28px;
  letter-spacing: 0;
}
.content-hallmarks .text-logo-celadon {
  font-size: 16px;
  color: #fff;
}
.h3-detail {
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 1.6px;
  color: #998642;
}
.in-the-present {
  font-size: 13px;
  letter-spacing: 1.3px;
  color: #373737;
  height: 18px;
  margin-bottom: 24px;
}
.h5-detail {
  font-size: 14px;
  letter-spacing: 1.4px;
  font-weight: 500;
  color: #373737;
  margin-bottom: 16px;
}
.text-content-detail {
  font-size: 14px;
  line-height: 21px;
  font-weight: lighter;
  color: #373737;
  letter-spacing: 0.6px;
  text-align: justify;
  margin-bottom: 20px;
}
.text-diff {
  letter-spacing: 0;
}
.hallmarks {
  height: 535px;
  margin-bottom: 5px;
  position: relative;
}
.content-hallmarks {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.6px;
  color: #f6f1ed;
  font-weight: lighter;
}
.container-content-hallmarks {
  position: absolute;
  left: 16px;
  bottom: 32px;
}
.ls-content-hallmarks {
  padding-left: 0;
  list-style: none;
  margin: 0;
  padding: 0;
}
.ls-content-hallmarks::after {
  content: '';
  display: block;
  clear: both;
}
.ls-content-hallmarks li {
  font-weight: lighter;
  font-size: 14px;
  letter-spacing: 0.6px;
  color: #fff;
}
.img-detail {
  max-width: 992px;
  height: auto;
}
.img-detail > img {
  width: 100%;
  height: auto;
}
.bee {
  margin: 10px 0 24px;
}
.sqm {
  font-weight: 300;
}
@media screen and (min-width: 576px) {
  .ls-content-hallmarks li {
    float: left;
    margin-right: 5px;
  }
  .dot-ls {
    display: none;
  }
  .content-detail {
    padding: 40px 32px;
  }
  .container-content-hallmarks {
    left: 32px;
    bottom: 50px;
  }
}
@media screen and (min-width: 768px) {
  .content-detail {
    padding: 73px 135px;
  }
  .hallmarks {
    height: 817px;
    margin-bottom: 0;
  }
  .content-hallmarks {
    margin-bottom: 18px;
  }
  .banner-celadon {
    height: 817px;
  }
}
@media screen and (min-width: 992px) {
  .compact,
  .customised {
    display: flex;
    flex-flow: row;
  }
  .content-detail,
  .img-detail {
    width: 50%;
    margin: 0;
  }
  .content-detail {
    padding: 0 32px;
    display: flex;
    flex-flow: column;
    justify-content: center;
  }
  .flex-reverse {
    display: flex;
    flex-flow: row-reverse;
  }
}
@media screen and (min-width: 1360px) {
  .content-detail {
    padding: 0 135px;
  }
  .container-content-hallmarks {
    left: 135px;
  }
}

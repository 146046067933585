.sm-gutters {
  margin-right: -10px;
  margin-left: -10px;

  > .col,
  > [class*='col-'] {
    padding-right: 10px;
    padding-left: 10px;
  }
}

$transition-base: all 0.3s ease;
$text-shadow-base: 0 1px 4px rgba(0, 0, 0, 0.8);

$bg-facebook: #3b579d;
$bg-twitter: #3eacde;
$bg-google: #dd4b39;
/* blue bold */
$cloud-burst: #232f5d;
/* black bold */
$mine-shaft: #373737;
/* white bold */
$springwood: #4d545d;
/* gray */
$jumbo: #4d545d;
/* white light */
$springwood: #4d545d;
$bg-chao: #f6f1ed;

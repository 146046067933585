/*
<div class="facebook-comment">
  <div class="fb-comments" data-href="https://developers.facebook.com/docs/plugins/comments" data-numposts="15"></div>
</div>
*/

.facebook-comment .fb_iframe_widget,
.facebook-comment .fb_iframe_widget span,
.facebook-comment .fb_iframe_widget span iframe[style],
.facebook-comment .fb-comments span,
.facebook-comment .fb-comments iframe {
  width: 100% !important;
}

/* ==========================================================================
Content
========================================================================== */
html,
body {
  height: 100%;
}

body {
  background: $bg-chao;
  font-size: 14px;
  font-family: 'AvenirBook', serif;
}

.wrapper {
  overflow: auto;
  min-height: 100%;
  @include flexbox;
  @include flex-flow(column nowrap);
}

.main {
  @include flex(1);
  .banner-home {
    height: 535px;
    background-size: cover;
  }
  .banner-services {
    height: 535px;
    background-position: center right;
  }
  .banner-contact {
    background-position: center right;
  }
  .coming-soon {
    padding: 206px 0;
    text-align: center;
    font-size: 16px;
    color: #444444;
    background-color: #f9f7f4;
  }
  .inner-content-pc {
    margin: 0 auto;
    width: 900px;
    padding-top: 50px;
    padding-bottom: 80px;
    .img-queen-pc {
      margin: 24px 0 44px;
    }
    .text-pc,
    .text-detail {
      color: #444444;
      font-weight: lighter;
      font-family: 'AvenirBook', serif;
      text-align: center;
    }
    .text-pc {
      font-size: 16px;
      line-height: 28px;
    }
    .text-detail {
      font-size: 14px;
      line-height: 24px;
      margin-top: 43px;
    }
  }
  .none-text {
    padding-bottom: 12px;
  }
  .text-celadon {
    width: 100%;
    margin: 0 auto;
    font-size: 16px;
    line-height: 28px;
    color: #373737;
    font-weight: lighter;
    padding: 100px 32px;
  }
  .inner-content {
    width: 900px;
    margin: 50px auto 39px;
    padding: 0 32px;
    .img-queen {
      margin: 24px 0 23px;
    }
    .text-home {
      font-size: 16px;
      line-height: 28px;
    }
    .text-about-us {
      margin-bottom: 65px;
      font-family: 'AvenirBook', serif;
      font-weight: lighter;
      font-size: 16px;
      line-height: 22px;
    }
  }
  //contact-us
  .box-contact-us {
    width: 50%;
    position: relative;
  }
  .img-queen-about-us {
    margin: 24px 0 44px;
    img {
      width: 42px;
      height: 24px;
    }
  }
  img {
    @include transition(all 0.3s ease-in-out);
  }
  .box-hover {
    position: absolute;
    left: 50%;
    bottom: 120px;
    transform: translate(-50%);
    color: #fff;
    width: 100%;
    text-align: center;
    z-index: 10;
    max-width: 75%;
    @include transition(all 0.3s ease-in-out);
    .text-vission {
      opacity: 0;
      visibility: hidden;
      @include transition(all 0.3s ease-in-out);
      font-family: 'AvenirBook', serif;
    }
  }
  .box-vission,
  .box-mission {
    @include transition(all 0.3s ease-in-out);
    background-color: rgba(0, 0, 0, 0);
    &:hover {
      background-color: rgba(0, 0, 0, 1);
      img {
        opacity: 0.6;
      }
      .box-hover {
        bottom: 50%;
        transform: translate(-50%, 50%);
        text-align: center;
      }
      .text-vission {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  .our-legacy {
    position: relative;
  }

  .inner-our-lagacy {
    position: absolute;
    bottom: 80px;
    width: 842px;
    text-align: center;
    margin: 0 auto;
    color: #fff;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    .text-our-lagacy {
      margin: 0 0 50px;
      font-size: 15px;
    }
  }

  .why-chao-global {
    position: relative;
    .h3-why-chao {
      color: $mine-shaft;
    }
    .box-wrapper {
      width: 75%;
      text-align: center;
      color: $mine-shaft;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    p {
      position: relative;
      font-family: 'AvenirBook', serif;
      font-size: 15px;
      line-height: 23px;
      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: -11px;
        left: 50%;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        width: 5px;
        height: 5px;
        background: $mine-shaft;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        margin: 0 auto;
      }
    }
    p.p-last {
      &:after {
        display: none;
      }
    }
    .box-img-half img {
      opacity: 0;
      visibility: hidden;
      @include transition(all 0.3s ease-in-out);
    }
    .why-chao-text {
      background: #f9f7f4;
      &:hover {
        // background-color: rgba(0,0,0,1);
        .box-img-half img {
          opacity: 1;
          @include transition(all 0.3s ease-in-out);
          visibility: visible;
        }
      }
    }
  }
}

@media (max-width: 991.98px) {
  .main {
    .inner-content {
      width: auto;
      margin: 0;
      padding: 25px 0 0 0;
    }
    .text-celadon {
      padding: 50px 32px;
    }
    .inner-content-pc {
      width: 100%;
      padding: 25px 32px 0;
      .img-queen-pc {
        margin: 16px 0 33px;
      }
      .h1,
      .h1-detail {
        margin: 0;
      }
      .text-pc {
        margin-bottom: 40px;
      }
      .text-detail {
        margin-top: 22px;
        margin-bottom: 40px;
      }
    }
    .none-text {
      padding-bottom: 44px;
    }
    .img-queen-about-us {
      margin: 30px 0 20px;
    }
    .inner-our-lagacy {
      width: 700px;
    }
    .home {
      .inner-content {
        margin-top: 20px;
      }
      .text-home {
        margin-bottom: 20px;
      }
    }
    .about-us-box-text {
      padding: 40px 25px 0;
    }
  }
}

@media (max-width: 575.98px) {
  .main .inner-content {
    width: auto;
    margin: 0;
    padding: 0 16px;
  }
  .main {
    //home
    .text-home {
      margin-bottom: 20px;
      text-align: justify;
    }
    //about-us
    .img-queen-about-us {
      margin: 20px 0 20px;
    }
    .banner-services {
      background-position: 85% bottom;
    }
    .banner-visionaries {
      background-position: 25% 0%;
    }
    .banner-contact {
      background-position: 80% bottom;
    }
    .banner-projects {
      background-position: 18% 0%;
    }
    .banner-partnership {
      background-position: top left;
    }
    .inner-content-pc {
      padding: 0 16px;
      .text-pc,
      .text-detail {
        text-align: justify;
      }
    }
    .text-celadon {
      padding: 50px 16px;
      .text-pc {
        text-align: justify;
      }
    }
    .box-contact-us {
      width: 100%;
    }
    .box-vission {
      margin-top: 10px;
    }

    .inner-our-lagacy {
      position: static;
      bottom: inherit;
      width: auto;
      text-align: center;
      margin: 0 auto;
      padding: 20px 10px 30px;
      color: $mine-shaft;
      left: inherit;
      transform: inherit;
      -webkit-transform: inherit;
      .text-our-lagacy {
        margin: 0 0 10px;
      }
      .h3-our-lagacy {
        color: #373737;
      }
    }
    .why-chao-global {
      .box-wrapper {
        padding: 40px 25px 20px;
        width: 100%;
        text-align: center;
        color: #373737;
        position: static;
        left: inherit;
        top: inherit;
        transform: inherit;
      }
    }
  }
}

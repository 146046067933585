.logo-brand {
  min-height: 219px;
  margin: 0 auto;
  margin: 0 0 60px;
  padding: 0;
}
.logo-brand > img {
  max-width: 100%;
  height: auto;
}
.banner-legacy-detail {
  height: 535px;
}
.container-legacy-detail {
  padding: 40px 16px;
  background-color: #f9f7f4;
  width: 100%;
}
.h4-legacy-detail {
  font-family: 'TrajanPro3', serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #373737;
  margin: 0;
  padding: 0;
}
.content-legacy-detail p {
  font-weight: lighter;
  line-height: 21px;
  color: #373737;
  padding: 0;
  margin: 0;
}
.legacy-detail-services {
  padding: 0;
  margin: 0 0 16px 0;
}
.legacy-detail-opened {
  margin: 0 0 12px 0;
}
.content-legacy-detail {
  padding: 0;
}
@media screen and (min-width: 576px) {
  .container-legacy-detail {
    padding: 40px 32px;
  }
}
@media screen and (min-width: 768px) {
  .container-legacy-detail {
    padding: 100px 64px 100px 32px;
    -ms-box-orient: horizontal;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    justify-content: flex-start;
  }
  .logo-brand {
    padding: 0 32px 0 0;
    align-self: center;
    -webkit-align-self: center;
    margin: 0;
  }
  .content-legacy-detail {
    max-width: 395px;
  }
  .banner-legacy-detail {
    height: 817px;
  }
}
@media screen and (min-width: 992px) {
  .container-legacy-detail {
    padding: 100px 135px;
  }
  .logo-brand {
    padding-right: 135px;
  }
}
@media screen and (min-width: 1280px) {
}

.in-good-hand {
  color: #f6f1ed;
  font-size: 14px;
  line-height: 28px;
  width: 172px;
  margin: 0 auto;
  padding-top: 200px;
}
.ls-services,
.ls-col,
.ls-col ul,
.ls-asset-management,
.ls-child {
  padding-left: 0;
  list-style: none;
  text-align: left;
}
.ls-services li {
  color: #373737;
  font-size: 14px;
  line-height: 21px;
}
.container-services {
  width: 100%;
  background-color: #f8f6f3;
  padding: 25px 0;
}
.ls-services h4 {
  color: #232f5d;
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 7px;
}
.ls-child > li {
  color: #444444;
}
.ls-col > li:first-child {
  margin-bottom: 28px;
}
.image-services img,
.image-mobile img {
  width: 100%;
  height: auto;
}
.image-mobile {
  width: 100%;
}
.image-services {
  display: none;
}
.small-title {
  margin-top: 14px;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 0;
}
@media screen and (min-width: 576px) {
  .container-services {
    padding: 25px 16px;
    position: relative;
  }
  .in-good-hand {
    position: absolute;
    left: 32px;
    padding-top: 20%;
  }
  .image-mobile {
    display: none;
  }
  .ls-services {
    position: relative;
    z-index: 0;
  }
  .image-services {
    display: block;
    position: absolute;
    z-index: -1;
    width: 362px;
    top: 50px;
    right: 0;
  }
}
@media screen and (min-width: 992px) {
  .in-good-hand {
    padding-top: 220px;
  }
  .image-services {
    top: 100%;
    transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    margin-top: -50px;
    left: 0;
    width: 100%;
    max-width: 1155px;
  }
  .image-hidden {
    display: none;
  }
  .ls-services > li {
    padding: 0;
  }
  .ls-services > li:nth-child(3) {
    align-self: stretch;
  }
  .container-services {
    padding: 72px 72px;
  }
}
@media (min-width: 1024px) {
  .col-pc-1 {
    flex: 0 0 10%;
    max-width: 10%;
  }
  .col-pc-2 {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .col-pc-3 {
    flex: 0 0 30%;
    max-width: 30%;
  }
  .col-pc-4 {
    flex: 0 0 40%;
    max-width: 40%;
  }
  .col-pc-5 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-pc-6 {
    flex: 0 0 60%;
    max-width: 60%;
  }
  .col-pc-7 {
    flex: 0 0 70%;
    max-width: 70%;
  }
  .col-pc-8 {
    flex: 0 0 80%;
    max-width: 80%;
  }
  .col-pc-9 {
    flex: 0 0 90%;
    max-width: 90%;
  }
  .col-pc-10 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (min-width: 1200px) {
  .container-services {
    padding-left: 135px;
    padding-right: 135px;
  }
  .in-good-hand {
    left: 135px;
  }
}
.col-pc-1,
.col-pc-2,
.col-pc-3,
.col-pc-4,
.col-pc-5,
.col-pc-6,
.col-pc-7,
.col-pc-8,
.col-pc-9,
.col-pc-10 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

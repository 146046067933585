.preloader {
  @include flexbox;
  @include flex-flow(column nowrap);
  @include justify-content(center);
  @include align-items(center);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  overflow: hidden;
  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
    background-color: #f6f1ed;
    @include transition(all 1s linear 0s);
  }
  &:before {
    left: 0;
    right: auto;
  }
  &:after {
    right: 0;
    left: auto;
  }
  .logo {
    position: relative;
    z-index: 1;
    @include transition(all 1s linear 0s);
    img {
      margin-left: auto;
      margin-right: auto;
      display: block;
      max-width: calculateRem(70px);
      margin-bottom: calculateRem(20px);
    }
    .slogan {
      font-size: calculateRem(30px);
      text-transform: uppercase;
    }
  }
  &.loaded {
    &:before {
      left: -50%;
    }
    &:after {
      right: -50%;
    }
    .logo {
      opacity: 0;
      visibility: hidden;
    }
  }
}

.container-policy {
  background-color: #f9f7f4;
  padding: 16px;
}
.container-policy p {
  line-height: 21px;
  color: #444444;
  text-align: justify;
}
.information-policy,
.how-to-use,
.cookies {
  margin-bottom: 28px;
}
.h4 {
  margin-bottom: 8px;
}
@media screen and (min-width: 576px) {
  .container-policy {
    padding: 32px;
  }
}
@media screen and (min-width: 992px) {
  .container-policy {
    padding: 60px 135px 80px;
  }
}

html.active-humburger {
  overflow: hidden;
}
.nav-main {
  background: rgba(255, 255, 255, 0.9);
  padding-top: 70px;
  width: 100%;
  position: absolute;
  top: 95px;
  left: 0;
  display: none;
  height: calc(100vh - 100px);
  z-index: 100;
  .menu-right {
    margin-left: 120px;
  }
  .nav-main-ul {
    margin: 0 135px;
    .nav-main-left {
      margin-left: 443px;
    }
    .nav-main-li {
      a {
        font-size: 30px;
        line-height: 41px;
        color: $mine-shaft;
        text-transform: uppercase;
        font-family: 'TrajanPro3', serif;
        &:hover {
          text-decoration: none;
          opacity: 0.8;
        }
      }
      ul {
        padding-left: 27px;
        margin: 8px 0 20px;
        li {
          margin: 0;
          list-style: none;
          margin-top: 15px;
          a {
            font-size: 15px;
            line-height: 15px;
            text-transform: capitalize;
            line-height: 0;
            font-family: 'AvenirBook', serif;
          }
        }
      }
      ul li a {
        font-size: 15px;
      }
    }
  }
}

/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */

/* Small devices (tablets, 768px and up) */
@media (max-width: 575.98px) {
  .nav-main {
    top: 54px;
    padding-top: 10px;
    height: calc(100vh - 54px);
    overflow: auto;
    padding-bottom: 10px;
    .nav-main-ul {
      margin: 0;
      .menu-right {
        margin: 0;
      }
      .nav-main-li a {
        font-size: 16px;
        line-height: 20px;
        color: #373737;
        text-transform: uppercase;
      }
      .nav-main-li {
        ul {
          li {
            margin-top: 5px;
          }
          margin: 5px 0 15px;
        }
        margin-top: 8px;
      }
      .nav-main-left {
        margin: 0;
      }
    }
  }
}

.container-contact {
  background-color: #f9f7f4;
  display: flex;
  flex-flow: column;
  padding: 25px 0;
}
.container-contact p {
  line-height: 24px;
}
.ls-contact {
  padding-left: 0;
  list-style: none;
  margin-top: 9px;
}
.team p {
  margin-bottom: 0;
}
.global-team,
.our-office {
  padding: 16px;
}
.h4 {
  margin-bottom: 23px;
}
.ls-contact li h6 {
  font-size: 15.5px;
}
@media (min-width: 768px) {
  .container-contact {
    padding: 40px 16px;
  }
}

@media (min-width: 992px) {
  .container-contact {
    flex-flow: row;
    padding: 80px 135px;
  }
  .global-team {
    margin-top: 3px;
    padding-left: 0;
    padding-right: 60px;
  }
}

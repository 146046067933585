.we-put-everything-we {
  font-size: 14px;
  color: #f6f1ed;
  letter-spacing: 2.1px;
  text-align: center;
  position: absolute;
  top: 420px;
  line-height: 28px;
}
.container-visionaries {
  font-family: 'AvenirBook', serif;
  width: 100%;
  background-color: #f9f7f4;
  padding: 0;
}
.information p {
  font-weight: 100;
  font-size: 14px;
  line-height: 24px;
  color: #373737;
  text-align: justify;
  margin-bottom: 0;
  margin-top: 4px;
}
.information h2 {
  font-size: 28px;
  color: #232f5d;
  text-align: center;
}
.office {
  text-transform: uppercase;
  color: #373737;
  font-size: 15px;
  text-align: center;
}
.image-visionaries img {
  width: 100%;
  height: auto;
}
.image-visionaries {
  max-width: 400px;
  margin: 0 auto;
  padding: 0;
  margin-bottom: 16px;
}
.visionaries {
  padding-top: 16px;
  padding-bottom: 16px;
}
.information {
  padding-left: 16px;
  padding-right: 16px;
}
@media screen and (min-width: 576px) {
  .information {
    padding-left: 32px;
    padding-right: 32px;
  }
  .we-put-everything-we {
    text-align: left;
    padding-left: 32px;
  }
  .image-visionaries {
    max-width: 331px;
  }
}
@media screen and (min-width: 768px) {
  .information h2,
  .office {
    text-align: left;
  }
  .container-visionaries {
    padding-left: 32px;
    padding-right: 32px;
  }
  .visionaries {
    display: flex;
    border-bottom: 1px solid #d9d9d9;
    padding: 65px 0;
  }
  .information {
    padding-right: 0;
    padding-left: 15px;
  }
  .image-visionaries {
    padding-left: 0;
    margin-top: 5px;
    margin-right: 15px;
  }
  .container-visionaries .visionaries:last-child {
    border-bottom: 0;
  }
}
@media screen and (min-width: 992px) {
  .we-put-everything-we {
    top: 253px;
    right: 135px;
  }
  .container-visionaries {
    padding-left: 135px;
    padding-right: 135px;
  }
}

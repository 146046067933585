.content {
  background-color: #f6f1ed;
  max-width: 1564px;
  margin: 0 auto;
}
.content-box {
  max-width: 782px;
  height: 80vh;
  padding: 0;
  position: relative;
  overflow: hidden;
}
.know-more {
  width: 100%;
  height: 100%;
  padding-left: 16px;
  padding-right: 16px;
  justify-content: center;
  position: absolute;
  top: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  transform: scale(1, 1);
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  -webkit-transition: all 0.7s ease 0s;
  -moz-transition: all 0.7s ease 0s;
  -ms-transition: all 0.7s ease 0s;
  -o-transition: all 0.7s ease 0s;
  transition: all 0.7s ease 0s;
}
.content-box:hover .know-more {
  top: 0;
}
.content-box > img {
  max-width: 782px;
  height: 100%;
}
.img-brand {
  height: auto;
  margin: 0 auto;
  margin-bottom: 32px;
  max-width: 352px;
}
.img-brand > img {
  width: 100%;
  height: auto;
}
.partner h6 {
  line-height: 28px;
  font-size: 15px;
  color: #444444;
  margin-bottom: 32px;
}
.partner {
  clear: both;
  background-color: #f9f7f4;
  padding: 16px 16px;
}
.ls-partner {
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: left;
}
.ls-partner li {
  font-size: 14px;
  line-height: 24px;
  color: #373737;
  padding: 0;
  margin: 0;
  font-weight: lighter;
}
.text-box-content {
  font-size: 15px;
  color: #373737;
  width: 100%;
  position: absolute;
  text-align: center;
  top: 50%;
  margin-top: -7.5px;
  a {
    color: #373737;
  }
  &:hover a {
    opacity: 0.8;
  }
}

@media screen and (min-width: 576px) {
  .partner {
    padding: 32px 32px;
  }
}

@media screen and (min-width: 768px) {
  .content-box {
    width: 50%;
    float: left;
  }
  #box-text {
    height: 30px;
    margin-top: 25%;
  }
  .text-box-content {
    top: 15px;
  }
  .content::after {
    content: '';
    display: block;
    clear: both;
  }
  .ls-partner:after {
    content: '';
    display: block;
    clear: both;
  }
  .ls-partner li:first-child {
    float: left;
    width: 50%;
  }
  .ls-partner li:last-child {
    float: left;
    width: 50%;
    padding-left: 32px;
  }
}
@media screen and (min-width: 992px) {
  .img-brand {
    margin-bottom: 98px;
  }
  .partner {
    padding-left: 64px;
  }
  .ls-partner li:last-child {
    padding-left: 64px;
  }
}
@media screen and (min-width: 1200px) {
  .partner {
    padding: 60px 135px 78px;
  }
  .ls-partner li:last-child {
    padding-left: 135px;
  }
}

.celadon,
.chao,
.your {
  width: 100%;
}
.celadon,
.chao {
  text-align: center;
}
.btn-chao {
  border: 0;
  margin: 69px 0 0 0;
}
.btn-chao-hover:hover {
  background-color: #44c6b5;
  color: #fff;
}
.btn-celadon-hover:hover {
  background-color: #af977e;
  color: #fff;
}
.celadon {
  background-color: #fff;
  padding: 115px 16px 115px;
  position: relative;
}
.chao {
  background-color: #f9f7f4;
  padding: 100px 16px 100px;
}
.your {
  padding: 70px 16px 70px;
  background-size: cover;
  background-position: center right;
}
.text2 {
  font-size: 16px;
  letter-spacing: 1.9px;
  color: #414141;
  margin: 16px 0 16px 0;
  height: 16px;
}
.text3-celadon {
  font-size: 16px;
}
.text1-celadon {
  font-size: 13px;
  letter-spacing: 0.8px;
}
.text1-celadon,
.text2,
.text3-celadon {
  font-weight: lighter;
  color: #414141;
}
.text1-chao {
  font-size: 16px;
  letter-spacing: 1px;
}
.text3-chao {
  font-size: 16px;
}
.text3-chao,
.text1-chao {
  font-weight: lighter;
  color: #595959;
}
.img-bee {
  position: absolute;
  right: 23%;
  bottom: 14%;
}
.img-chao {
  max-width: 687px;
  height: auto;
  margin: 0 auto;
  margin-bottom: 24px;
}
.img-celadon {
  max-width: 420px;
  margin: 0 auto;
  height: auto;
  margin-bottom: 24px;
}
.img-chao > img,
.img-celadon > img {
  width: 100%;
  height: auto;
}
.your-our-brand,
.from-idea {
  color: #fff;
}
.your-our-brand {
  font-weight: 500;
  font-size: 30px;
  margin-bottom: 16px;
}
.from-idea {
  font-size: 16px;
  line-height: 28px;
}
.process {
  font-size: 14px;
  color: #f9f7f4;
}
.ls-process {
  padding-left: 0;
  list-style: none;
  color: #f9f7f4;
  font-size: 14px;
}
.from-idea::after {
  content: '';
  width: 33px;
  height: 2px;
  background-color: #f9f7f4;
  margin: 20px 0 27px 0;
  display: block;
}
.ls-process li {
  padding: 0;
  margin: 20px 0 0 0;
  height: 14px;
  font-weight: lighter;
}
.text-end {
  font-weight: lighter;
  font-size: 14px;
  line-height: 24px;
  color: #f9f7f4;
  margin: 24px 0 0 0;
  max-width: 500px;
}
@media screen and (min-width: 576px) {
  .your {
    padding-left: 32px;
    padding-right: 32px;
  }
  .celadon,
  .chao {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media screen and (min-width: 768px) {
  .your {
    padding: 145px 0 145px 32px;
  }
  .chao {
    padding: 150px 32px;
  }
  .celadon {
    padding: 192px 32px;
  }
}
@media screen and (min-width: 992px) {
  .your {
    padding: 192px 0 192px 135px;
  }
  .chao {
    padding: 218px 0;
  }
  .celadon {
    padding: 272px 0;
  }
  .img-bee {
    right: 341px;
    bottom: 120px;
  }
}

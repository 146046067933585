.btn-chao {
  color: #fff;
  line-height: 21px;
  text-transform: uppercase;
  font-size: 14px;
  padding: 14px 42px;
  transition: 0.3s all ease-in-out;
  border-radius: 0;
  -webkit-border-radius: 0;
  -o-border-radius: 0;
  &:hover {
    background: #fff;
    color: #333;
  }
}
.btn-black {
  background: #1d1d1b;
}
.btn-green {
  background: #74c6b5;
}

.btn-blue {
  background: #232f5d;
}

.btn-brown {
  background: #af976d;
}
.btn-white {
  color: #232f5d;
  background-color: #fff;
  opacity: 1;
  text-transform: uppercase;
  font-size: 14px;
  padding: 14px 42px;
  transition: 0.3s all ease-in-out;
  border-radius: 0;
  -webkit-border-radius: 0;
  -o-border-radius: 0;
  border: 0;
  &:hover {
    background-color: #232f5d;
    color: #fff;
  }
}

@media (max-width: 575.98px) {
  .btn-chao {
    padding: 7px 21px;
    line-height: 22px;
    font-size: 12px;
  }
}

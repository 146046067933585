.main {
  .h1-sitemap {
    letter-spacing: 3.2px;
  }
  .container-sitemap {
    background-color: #f9f7f4;
    a {
      text-decoration: none;
      margin: 0;
    }
    h6 {
      font-size: 16px;
      line-height: 28px;
    }
    h6 a {
      color: #000000;
    }
    .ls-sitemap {
      padding-left: 0;
      list-style: none;
    }
    .ls-sitemap li {
      padding: 16px;
    }
    .ls-child {
      list-style: none;
      padding-left: 8px;
      color: #373737;
      line-height: 24px;
      margin-top: 4px;
    }
    .ls-child li {
      padding: 0;
    }

    .ls-child li a {
      color: #373737;
    }
  }
}
@media screen and (min-width: 576px) {
  .main {
    .container-sitemap {
      padding: 16px;
    }
  }
}
@media screen and (min-width: 768px) {
  .ls-sitemap {
    display: flex;
    justify-content: space-between;
    padding: 0;
  }
}
@media screen and (min-width: 992px) {
  .main {
    .container-sitemap {
      padding: 44px 135px 39px;
    }
    .ls-center-1 {
      width: 25%;
      margin-left: 8%;
    }
    .ls-center-2 {
      width: 25%;
      margin-left: 4%;
    }
  }
}
